const state = {
  address: null,
  paymentMethod: null,
  deliveryMethod: null
}

const mutations = {
  SET_ADDRESS (state, value) {
      state.address = value
  },
  SET_PAYMENT_METHOD (state, value) {
      state.paymentMethod = value
  },
  SET_DELIVERY_METHOD (state, value) {
    state.deliveryMethod = value
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
