import Vue from 'vue'

Vue.mixin({
  methods: {
    openModal (id, params) {
      this.$store.dispatch('modal/open', { id, ...params })
    },
    closeModal (id) {
      this.$store.dispatch('modal/close', id)
    },
    modalProps (id) {
      return this.$store.getters['modal/getModal'](id) || { show: false }
    }
  },
  computed: {
    modals () {
      return this.$store.state.modal.modals
    }
  }
})
