import Vue from 'vue'
import Vuex from 'vuex'

import service from './service'
import webcontent from './webcontent'
import order from './order'
import modal from './modal'
import socket from './socket'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        service: service,
        webcontent,
        order,
        modal,
        socket
    }
})

export default store
