import axios from 'axios'

class BaseApi {
    constructor (config) {
        this.baseConfig = config ? typeof config === 'string' ? JSON.parse(config) : { ...config } : {}
    }

    api () {
        const baseApi = axios.create({
            baseURL: this.baseConfig.BaseUrl,
            headers: {
              Accept: 'application/json'
            }
        })
        baseApi._token = this.baseConfig.Token
        baseApi._token2 = this.baseConfig.Token2
        baseApi.interceptors.request.use((config) => {
            config.headers.Authorization = 'Bearer ' + (baseApi._token || baseApi._token2)
            return config
        }, function (error) {
            return Promise.reject(error)
        })
        baseApi.interceptors.response.use((response) => {
            if (typeof response.data !== 'undefined') return response.data
            return response
        }, (error) => {
            const { response = {} } = error
            const { data } = response
            if (data) {
                return Promise.reject(data)
            }
            return Promise.reject(error)
        })
        return baseApi
    }
}

export default BaseApi
