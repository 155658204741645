import moment from 'moment'
import Vue from 'vue'
import BaseApi from '@/libs/baseApi'
import { device } from '@/libs/portalApi'
var serialize = require('form-serialize')

Vue.mixin({
    methods: {
        async uploadFile (e) {
            var fullName = this.company.User.Name
            var split = fullName.split(' ')
            const fd = new FormData()
            fd.append('Image1', e.target.files[0])
            fd.append('FirstName', split[0])
            fd.append('LastName', split[1])
            fd.append('PhoneNo', this.company.User.PhoneNo)
            await this.$baseApi.post('/user/edit', fd)
        },
        activeCondition (item, value) {
            var temp
            var result
            if (!item) return false
            if (!Array.isArray(item) && typeof item === 'object') {
                if (item.Condition) {
                    item.Condition.forEach(x => {
                        if (!temp) temp = this[x]
                        else temp = temp[x]
                    })
                }
            }
            if (item.Value === 'index') {
                if (temp === value) result = true
            } else {
                if (temp === this.stringReader(item.Value, value)) result = true
            }
            return result
        },
        conditionHideWhen (data, res) {
            var value
            var temp
            if (!Array.isArray(data) && typeof data === 'object') {
                if (data.Condition) {
                    data.Condition.forEach(item => {
                        if (!temp) temp = this[item]
                        else temp = temp[item]
                    })
                    console.log('islogin', temp)
                    if (data.Value !== 'index' && typeof data.Value === 'string') {
                        if (temp === data.Value) value = false
                        else value = true
                    } else if (data.Value === 'index') {
                        if (temp !== res) value = false
                        else value = true
                    } else if (Array.isArray(data.Value)) {
                        if (data.Value.indexOf(temp) >= 0) value = false
                        else value = true
                    } else if (typeof data.Value === 'boolean') {
                        if (data.Value === temp) value = false
                        else value = true
                    }
                }
            } else value = true
            return value
        },
        sleep (ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },
        async allAction (action, value) {
            var data = (action.Id) ? this.getData(action.Id) : null
            var res
            if (action.Type === 'redirectUrl') {
                var uri = this.stringReader(action.Url, value || data)
                this.$router.push(uri)
            } else if (action.Type === 'open') window.open(action.Url)
            else if (action.Type === 'tab') this.$tabs.value = value
            else if (action.Type === 'login') {
                res = await this.$portalApi({
                    method: 'POST',
                    data,
                    url: action.PortalPost
                })
                this.loginDefaultFunction(res)
            } else if (action.Type === 'post') {
                res = await this.$baseApi({
                    method: 'POST',
                    data,
                    url: action.Post
                })
            } else if (action.Type === 'saveto') {
                this.getTarget(action, value)
            }
        },
        getTarget (action, value) {
            var index
            var targetValue = this.stringReader(action.Target)
            if (Array.isArray(targetValue)) {
                if (action.ComparingKey) index = targetValue.findIndex(item => item[action.ComparingKey] === value[action.ComparingKey])
                else index = targetValue.indexOf(value)
                if (index === -1) targetValue.push(value)
                else targetValue.splice(index, 1)
            }
        },
        async mainAction (action, value) {
            if (!Array.isArray(action)) this.allAction(action, value)
            else {
                action.forEach(async (item) => {
                    await this.allAction(item, value)
                })
            }
        },
        async loginDefaultFunction (res) {
            var config = localStorage.getItem('config')
            config = JSON.parse(config)
            config.Token = res.access_token
            localStorage.setItem('config', JSON.stringify(config))
            var newBaseApi = new BaseApi(localStorage.getItem('config'))
            Vue.prototype.$baseApi = newBaseApi.api()
            await this.getCompany()
            this.$router.push('/home')
        },
        leafletMap (data) {
            this.removeLeafletMap()
            // eslint-disable-next-line no-undef
            this.map = new L.Map('map', {
                dragging: data.disableScroll,
                // fullscreenControl: true,
                // OR
                fullscreenControl: {
                    pseudoFullscreen: false // if true, fullscreen to page width and height
                }
            }).setView([data.Latitude, data.Longitude], 13)
            // eslint-disable-next-line no-undef
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map)

            // eslint-disable-next-line no-undef
            L.marker([data.Latitude, data.Longitude]).addTo(this.map)
                .bindPopup(data.Name)
                .openPopup()
            return false
        },
        removeLeafletMap () {
          if (this.map) {
            this.map.off()
            this.map.remove()
          }
        },
        formatNumber (num, decimalDigit = 2) {
            num = parseFloat(num)
            var roundingDigit = 1
            for (var i = 1; i <= decimalDigit; i++) {
                roundingDigit = roundingDigit * 10
            }
            const options = {
              minimumFractionDigits: decimalDigit,
              maximumFractionDigits: decimalDigit
            }
            var decimal = num.toFixed(2) - num
            if (decimal > 0) num = Math.floor(num * roundingDigit) / roundingDigit
            else num = num.toFixed(2)
            return Number(num).toLocaleString('en', options)
        },
        // formatNumber (number) {
        //     return number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        // },
        formatDate (date, format) {
            date = moment(new Date(date)).format(format)
            return date
        },
        getData (id) {
            if (typeof id !== 'string') return false
            var form = document.querySelector('#' + id)
            var obj = serialize(form, { empty: true })
            for (var x in obj) {
                if (obj[x] === '') obj[x] = null
                else if (obj[x].indexOf('{') === 0 || obj[x].indexOf('[') === 0) {
                    obj[x] = JSON.parse(obj[x])
                }
            }
            if (obj['NULL'] && Array.isArray(obj['NULL'])) obj = [...obj['NULL']] // eslint-disable-line
            if (obj['NULL'] && !Array.isArray(obj['NULL']) && typeof obj['NULL'] === 'object') obj = {...obj['NULL']} // eslint-disable-line
            return obj
        },
        onsubmit (e) {
            e.preventDefault()
        },
        openLink (link, target = '_system') {
            if (this.$route.path === '/register') target = '_blank'
            window.cordova.InAppBrowser.open(link, target, 'closebuttoncaption=Close,location=yes')
        },
        async copyText (text) {
          return new Promise((resolve, reject) => {
            if (this.isCordova) {
              return window.cordova.plugins.clipboard.copy(text, resolve, reject)
            }
            return navigator.clipboard.writeText(text).then(resolve).catch(reject)
          })
        },
        async logout () {
          const loading = this.$buefy.loading.open()
          try {
            await this.$portalApi.post('/auth/logout')
          } catch (err) {
            console.error(err.message)
          } finally {
            this.removeTokenFromConfig()
            this.onesignallogout()
            if (this.appType === 'vaddress') {
              this.$router.replace({ name: 'login' })
            } else {
              this.$router.replace({ name: 'home' })
            }
            this.disconnectSocket()
            loading.close()
          }
        },
        async deleteAccount () {
          const loading = this.$buefy.loading.open()
          try {
            await this.$baseApi.post('/user/deleteaccount')
          } catch (err) {
            console.log('err', err)
          } finally {
            this.removeTokenFromConfig()
            this.onesignallogout()
            if (this.appType === 'vaddress') {
              this.$router.replace({ name: 'login' })
            } else {
              this.$router.replace({ name: 'home' })
            }
            this.disconnectSocket()
            loading.close()
          }
        },
        removeTokenFromConfig () {
          const config = JSON.parse(localStorage.getItem('config') || '{}')
          config.Token = null
          localStorage.setItem('config', JSON.stringify(config))

          const newBaseApi = new BaseApi(localStorage.getItem('config'))
          Vue.prototype.$baseApi = newBaseApi.api()

          return this.getCompany()
        },
        onesignal () {
          if (!window.OneSignal) return
          const appId = '943d79d0-d6a2-4c86-9f32-096797e89a20'
          const company = JSON.parse(localStorage.getItem('company') || '{}')
          var OneSignal = window.OneSignal || window.plugins?.OneSignal
          if (!OneSignal) return
          if (OneSignal.default) OneSignal = OneSignal.default
          const setUserOid = () => {
              var user = company.User && company.User.Oid
              OneSignal.setExternalUserId(user)
              OneSignal.sendTags({
                  id: user
              })
          }
          if (OneSignal.push) {
              OneSignal.push(() => {
                  OneSignal.init({
                      appId,
                      safari_web_id: '',
                      notifyButton: {
                          enable: false
                      },
                      promptOptions: {
                          slidedown: {
                              enabled: true,
                              autoPrompt: true
                          }
                        }
                  })
                  OneSignal.on('subscriptionChange', function (isSubscribed) {
                      console.log("The user's subscription state is now:", isSubscribed)
                  })
                  setUserOid()
              })
          } else {
              OneSignal.setLogLevel(6, 0)
              var notificationOpenedCallback = function (jsonData) {
                  console.log('notificationOpenedCallback:' + JSON.stringify(jsonData))
              }
              OneSignal.setAppId('943d79d0-d6a2-4c86-9f32-096797e89a20')
              OneSignal.setNotificationOpenedHandler(notificationOpenedCallback)
              OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                  console.log('User accepted notifications: ' + accepted)
              })
              setUserOid()
          }
        },
        onesignallogin () {
          const tags = {
            id: this.user.Oid
          }
          this.sendOnesignalTags(tags)
        },
        onesignallogout () {
          if (this.isCordova) {
            window.plugins.OneSignal.getTags((tags) => {
              window.plugins.OneSignal.deleteTags(Object.keys(tags))
            })
          } else if (window.OneSignal) {
            window.OneSignal.push(() => {
              window.OneSignal.getTags((tags) => {
                window.OneSignal.deleteTags(Object.keys(tags))
              })
            })
          }
        },
        disconnectSocket () {
          this.$socket.emit('disconnect user', device)
          this.$socket.disconnect()
          this.$store.dispatch('socket/logOut')
        },
        sendOnesignalTags (tags) {
          if (this.isCordova) {
            // window.plugins.OneSignal.sendTags(tags)
          } else if (window.OneSignal) {
            // window.OneSignal.push(() => {
            //   window.OneSignal.sendTags(tags)
            // })
          }
        },
        async getCompany () {
            const response = await this.$baseApi.get('/company')
            localStorage.setItem('company', JSON.stringify(response))
            this.$store.commit('service/SET_COMPANY', response)
            var body = document.getElementsByTagName('body')[0]
            // body.style.fontFamily = response.Font1
            body.style.fontFamily = 'Nunito'
        },
        openWindow () {
          var path = this.$route.path
          const routeData = this.$router.resolve(path)
          window.open(routeData.href, '_blank')
        }
    },
    computed: {
        windowWidth () {
            return this.$store.state.service.windowWidth
        },
        bottomHeight () {
            return this.$store.state.service.bottomHeight
        },
        company () {
            return this.$store.state.service.company
        },
        isLg () {
          return this.windowWidth >= 1024
        },
        isMd () {
          return this.windowWidth >= 768
        },
        isSm () {
          return this.windowWidth < 768
        },
        user () {
          return this.$store.getters['service/user']
        },
        isCordova () {
          return typeof cordova !== 'undefined'
        },
        isMobile () {
          const isMobile = () => {
            const { userAgent } = window.navigator
            return ['ipad', 'iphone', 'android'].reduce((value, os) => {
              if (value) return value
              if (userAgent.toLowerCase().indexOf(os) !== -1) return true
            }, false)
          }
          return this.isAndroid || this.isIos || this.isSm || isMobile()
        },
        isAndroid () {
          return (this.isCordova && window.cordova.platformId === 'android') || (navigator && navigator.userAgent.indexOf('Android') !== -1)
        },
        isIos () {
          return (this.isCordova && window.cordova.platformId === 'ios') || (navigator && navigator.userAgent.indexOf('iOS') !== -1)
        },
        appType () {
          return this.$config.AppType
        }
    }
})
