import axios from 'axios'
console.log(process.env)
// export const baseApiUrl = process.env.VUE_APP_BASE_API_URL || 'https://api-ipost.elsoft.id/api/ecommerce/v1'
export const baseApiUrl = process.env.VUE_APP_BASE_API_URL || 'https://api-ipost.elsoft.id/api/ecommerce/v2'

class Config {
    constructor () {
        // this.url = process.env.VUE_APP_HOST || (window.location.host.search('localhost') >= 0 ? 'ilog.id' : window.location.host)
        // this.url = process.env.VUE_APP_HOST || (window.location.host.search('localhost') >= 0 ? 'elite.ezbooking.co' : window.location.host)
        this.url = process.env.VUE_APP_HOST || (window.location.host.search('localhost') >= 0 ? 'vaddress.ezbooking.co' : 'vaddress.ezbooking.co')
    }

    async mobile () {
        if (typeof (cordova) === 'undefined') {
          return
        }

        this.url = typeof (cordova) !== 'undefined' ? navigator.appInfo.identifier : this.url
        var response = await axios.get(`${baseApiUrl}/base?web=` + this.url)
        const config = JSON.parse(localStorage.getItem('config')) || {}
        localStorage.setItem('apptype', response.data.AppType || 'ecommerce')
        localStorage.setItem('config', JSON.stringify({
          ...config,
          ...response.data,
          Token: config.Token,
          Token2: response.data.Token
        }))
    }

    async web () {
        var response = null
        var baseOid = null
        if (window.location.href.search('localhost') >= 0 || window.location.href.search('elite.ezbooking.co') >= 0 || window.location.href.search('book.ezbooking.co') >= 0) baseOid = window.location.href.split('=')
        if (typeof (cordova) !== 'undefined') return false
        if (window.location.href.search('localhost') >= 0 || window.location.href.search('elite.ezbooking.co') >= 0 || window.location.href.search('book.ezbooking.co') >= 0) response = await axios.get(`${baseApiUrl}/base?web=` + this.url + '&id=' + baseOid[1])
        else response = await axios.get(`${baseApiUrl}/base?web=` + this.url)
        const config = JSON.parse(localStorage.getItem('config')) || {}
        localStorage.setItem('apptype', response.data.AppType || 'ecommerce')
        localStorage.setItem('config', JSON.stringify({
          ...config,
          ...response.data,
          Token: config.Token,
          Token2: response.data.Token
        }))
    }
}

Config.get = () => {
  return JSON.parse(localStorage.getItem('config'))
}

export default Config
