import Vue from 'vue'
Vue.mixin({
    methods: {
        stringReader (url, value) {
            // var firstvariable = '{'
            // var secondvariable = '}'
            // var result = url.match(new RegExp(firstvariable + '(.*)' + secondvariable))
            // var changes = value[result[1]]
            // url = url.replace(result[0], changes)
            var newUrl = url
            var result = newUrl.split(/[{}]/)
            var query = this.$route.query
            var params = this.$route.params
            var parent = this.getData('prev').Prev
            var split, temp, changes
            result.forEach(item => {
                if (item === '') return false
                if (item.includes('UrlQuery')) changes = query[item.split('.')[1]]
                else if (item.includes('UrlParams')) changes = params[item.split('.')[1]].toUpperCase()
                else if (value) changes = value[item] || value
                else {
                    split = item.split('.')
                    split.forEach(x => {
                        if (!temp && x !== 'parent') temp = this[x]
                        else if (!temp && x === 'parent') temp = parent
                        else if (temp && temp[x]) temp = temp[x]
                    })
                    changes = temp
                }
                if (typeof changes === 'string' || typeof changes === 'number') newUrl = newUrl.replace('{' + item + '}', changes)
            })
            return typeof changes === 'object' ? changes : newUrl
        }
    }
})
