import BaseApi from './baseApi'

class AdminApi extends BaseApi {
    constructor (config) {
      super(config)
      this.baseConfig.BaseUrl = process.env.VUE_APP_BASE_ADMIN_API_URL || 'https://api-app.elsoft.id/admin/api/v1'
    }
}

export default AdminApi
