import Vue from 'vue'
import Router from 'vue-router'
// import mode from './mode'

Vue.use(Router)

const router = new Router({
    // mode: mode,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '',
            component: () => import('@/layouts/main/Main.vue'),
            redirect: '/home',
            children: [
                {
                    path: '/home',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: '/aboutus',
                    component: () => import('@/views/apps/aboutus')
                },
                {
                    path: '/contactus',
                    component: () => import('@/views/apps/contactus')
                },
                {
                    path: '/faq',
                    component: () => import('@/views/apps/faq')
                },
                {
                    path: '/cart',
                    component: () => import('@/views/apps/home')
                },
                // {
                //     path: '/page/term',
                //     component: () => import('@/views/apps/term')
                // },
                // {
                //     path: '/page/policy',
                //     component: () => import('@/views/apps/policy')
                // },
                {
                    path: '/page/:module',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: '/item/detail',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: '/product',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: '/item/history',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: 'item/historydetail',
                    component: () => import('@/views/apps/home')
                }
            ]
        },
        {
            path: '',
            component: () => import('@/layouts/main/Full.vue'),
            children: [
                {
                    path: '/item/addon',
                    component: () => import('@/views/apps/order')
                },
                {
                    path: '/item/payment',
                    component: () => import('@/views/apps/order')
                },
                {
                    path: '/item/success',
                    component: () => import('@/views/apps/order')
                },
                {
                    path: '/item/havepaid',
                    component: () => import('@/views/apps/havepaid')
                },
                {
                    path: '/item/successva',
                    component: () => import('@/views/apps/successva')
                },
                {
                    path: '/auth/google/callback',
                    component: () => import('@/views/apps/home')
                }
            ]
        },
        {
            path: '',
            component: () => import('@/layouts/main/Dashboard.vue'),
            children: [
                {
                    path: '/users/:module',
                    component: () => import('@/views/apps/home')
                },
                {
                    path: '/users/:module/:type',
                    component: () => import('@/views/apps/home')
                }
            ]
        },
        {
            path: '*',
            redirect: typeof (cordova) !== 'undefined' ? '/dashboard' : '/page-not-found'
        }
    ]
})

router.afterEach(() => {
    console.log('after each')
})

export default router
