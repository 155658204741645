import { device as Device } from '@/libs/portalApi'
import VueApp from '../../main'
import moment from 'moment'

export default {
  connectSocket () {
    const socket = this._vm.$socket
    if (!socket.connected) {
      const { Token, Token2 } = this._vm.$config
      if (!socket.io.opts.query) socket.io.opts.query = {}
      socket.io.opts.query.token = Token || Token2
      socket.connect()
    }
  },
  connectServer ({ rootGetters, rootState }, server) {
    const user = getUser(rootGetters)
    if (user && user.Oid) {
      server.emit('new user', { userId: user.Oid, system: 'administrator', companyId: user.Oid })
    }
  },
  async getHistory ({ commit, dispatch, rootGetters, rootState }) {
    dispatch('connectSocket')
    const user = getUser(rootGetters)
    const res = await this._vm.$portalApi.post('/chat/history', {
      user: user.Oid,
      support: true,
      system: getCompanyId(rootState),
      Device
    })
    commit('SET_ROOM', typeof res.data !== 'undefined' ? { Unseen: 0, Details: [] } : {
      ...res,
      Details: res.Details.sort((a, b) => moment(a.CreatedAt).valueOf() - moment(b.CreatedAt).valueOf()).reduce((p, v) => {
        const chat = buildMessage(v, rootGetters)
        const { Date } = chat
        if (!p[Date]) p[Date] = []
        p[Date].push(chat)
        return p
      }, {})

    })
    dispatch('readNotification')
  },
  async getNotification ({ commit, rootGetters, dispatch }) {
    dispatch('connectSocket')
    const user = getUser(rootGetters)
    const res = await this._vm.$portalApi.post('/chat/notification/list', {
      user: user.Oid
    })
    const notif = res && res[0] ? res[0].UnreadMessages : 0
    commit('SET_UNREAD', notif)
  },
  readNotification ({ commit, rootGetters }) {
    const user = getUser(rootGetters)
    this._vm.$portalApi.post('/chat/notification/read', {
      user: user.Oid
    })
    commit('READ')
  },
  pushMessage ({ commit, rootGetters }, Message) {
    const message = buildMessage(Message, rootGetters)
    commit('ADD_MESSAGE', message)
    return message
  },
  async sendMessage ({ commit, dispatch, rootGetters, rootState }, Message) {
    const message = await dispatch('pushMessage', Message)
    const res = await this._vm.$portalApi.post('/chat/send', {
      user: message.User,
      support: true,
      system: getCompanyId(rootState),
      Message,
      Device
    })
    commit('UPDATE_MESSAGE', { ...res, ...message })
    dispatch('readNotification')
  },
  receiveMessage ({ commit, dispatch }, message) {
    console.log(message)
    dispatch('pushMessage', message.Detail)
    if (VueApp.router.history.current.path.indexOf('chat') === -1) {
      commit('INC_UNREAD')
    } else {
      dispatch('readNotification')
    }
  },
  logOut ({ commit }) {
    commit('LOGOUT')
  }
}

function getUser (rootGetters) {
  return rootGetters['service/user']
}

function getCompanyId (rootState) {
  return rootState.service.company.Company
}

function buildMessage (Message, rootGetters) {
  const user = getUser(rootGetters)
  const chat = {
    MessageType: 'text',
    Key: Math.random().toString(36),
    Status: 'pending'
  }
  if (typeof Message === 'object') {
    return {
      ...Message,
      ...chat,
      ...getDateTime(Message.CreatedAt),
      UserObj: Message.User === user.Oid ? user : Message.UserObj
    }
  }
  return {
    ...chat,
    Message,
    ...getDateTime(Message.CreatedAt),
    UserObj: user,
    User: user.Oid
  }
}

function getDateTime (createdAt = null) {
  if (!createdAt) createdAt = moment().format('YYYY-MM-DD HH:mm')
    const [Date, Time] = createdAt.split(' ')
  return { Date, Time }
}
