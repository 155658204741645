import axios from 'axios'
import Vue from 'vue'

export const device = 'web_' + new Date().getTime()

const portalApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_PORTAL_API_URL || 'https://api-core.elsoft.id/portal/api'
})

portalApi.interceptors.request.use((config) => {
  if (Vue.prototype.$baseApi && Vue.prototype.$baseApi._token) {
    config.headers.Authorization = 'Bearer ' + Vue.prototype.$baseApi._token
  }
    return config
}, function (error) {
    return Promise.reject(error)
})

portalApi.interceptors.response.use((response) => {
    return response.data ? response.data : response
}, (error) => {
    const { response = {} } = error
    const { data } = response
    if (data) {
        return Promise.reject(data)
    }
    return Promise.reject(error)
})

export default portalApi
