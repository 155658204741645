import Vue from 'vue'

const state = {
  modals: []
}

const mutations = {
  OPEN_MODAL (state, { id, ...params }) {
    state.modals.push({
      id, show: true, ...params
    })
  },
  CLOSE_MODAL (state, id) {
    const index = state.modals.findIndex(m => m.id === id)
    Vue.delete(state.modals, index)
  }
}

const actions = {
  open ({ commit, dispatch }, params) {
    commit('OPEN_MODAL', params)
    if (state.modals.length === 1) {
      registerListener(() => {
        dispatch('closeLastModal')
      })
    }
  },
  close ({ commit, state }, id) {
    commit('CLOSE_MODAL', id)
    if (!state.modals.length) {
      removeListener()
    }
  },
  closeLastModal ({ dispatch, state }) {
    if (!state.modals.length) return
    dispatch('close', state.modals[state.modals.length - 1].id)
  }
}

const getters = {
  getModal: (state) => (id) => {
    return state.modals.find(m => m.id === id)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

let listenerCallback
function registerListener (cb) {
  if (typeof cordova === 'undefined') return
  listenerCallback = cb
  document.addEventListener('backbutton', listenerCallback, false)
}

function removeListener () {
  if (typeof cordova === 'undefined') return
  document.removeEventListener('backbutton', listenerCallback)
  listenerCallback = null
}
