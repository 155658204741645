import Vue from 'vue'

Vue.mixin({
  methods: {
    async uploadImageS3 (data) {
      const file = parseFile(data)
      const { type, ext } = getImageProps(file)

      const { UploadURL, URL } = await this.$adminApi.post('image/client', {
        Image: { type, ext }
      })

      await uploadS3(file, UploadURL)

      return URL
    },
    async uploadFileS3 (data) {
      const file = parseFile(data)
      const { type, ext } = getFileProps(file)

      const { UploadURL, URL } = await this.$adminApi.post('file/upload/client', {
        File: { type, ext }
      })

      await uploadS3(file, UploadURL)

      return URL
    },
    async saveServerImage (id, url, fileData) {
      const file = parseFile(fileData)
      const { ext, size } = getImageProps(file)

      return this.$adminApi.post(`/image/${id}`, { url, ext, size })
    }
  }
})

function uploadS3 (file, uploadUrl) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', uploadUrl)
    xhr.send(file)
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400) {
            resolve()
        } else if (xhr.status >= 400) {
            console.log(xhr.response)
            reject(new Error('error uploading file'))
        }
    }
  })
}

function parseFile (file) {
  return typeof file === 'string' ? base64toBlob(file) : file
}

function getFileProps (file) {
  return {
    type: file.type || 'application/pdf',
    ext: file.ext || 'pdf',
    size: file.size
  }
}

function getImageProps (file) {
  return {
    type: file.type || 'image/png',
    ext: file.ext || 'png',
    size: file.size
  }
}

function base64toBlob (dataURI) {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}
