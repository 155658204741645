import Vue from 'vue'

const state = {
  response: {}
}

const mutations = {
  SET_WEBCONTENT (state, { path, response }) {
    Vue.set(state.response, path, response)
  },
  SET_WEBCONTENTS (state, payload) {
    state.response = payload
  },
  REMOVE_WEBCONTENT (state, path) {
    Vue.delete(state.response, path)
  }
}

const actions = {
  async get ({ dispatch, commit }, { path, cache = true }) {
    const response = await Vue.prototype.$baseApi.get(path)
    const payload = {
      path,
      response
    }
    commit('SET_WEBCONTENT', payload)
    if (cache) dispatch('cacheContent', payload)
    return response
  },
  set ({ commit }, { path, response }) {
    console.log(response)
    commit('SET_WEBCONTENT', { path, response })
  },
  remove ({ commit }, path) {
    commit('REMOVE_WEBCONTENT', path)
  },
  loadFromCache ({ commit }) {
    const content = JSON.parse(localStorage.getItem('webcontent') || '{}')
    commit('SET_WEBCONTENTS', content || {})
  },
  cacheContent (ctx, { path, response }) {
    const content = JSON.parse(localStorage.getItem('webcontent') || '{}')
    content[path] = response
    localStorage.setItem('webcontent', JSON.stringify(content))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
