
<script>
export default {
    methods: {
        windowResize () {
            this.$store.commit('service/SET_WINDOW_WIDTH', window.innerWidth)
        }
    },
    created () {
        window.addEventListener('resize', this.windowResize, false)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.windowResize, false)
    }
}
</script>
<style>
    .map {
        z-index: 0;
    }
</style>
