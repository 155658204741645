
export default {
  SET_ROOM (state, data) {
    if (data) {
      if (!data.Unseen) data.Unseen = 0
      state.room = data
    } else {
      state.room = { Unseen: 0, Details: [] }
    }
  },
  ADD_MESSAGE (state, message) {
    if (!state.room) state.room = { Details: [] }
    const date = message.Date
    if (!state.room.Details[date]) state.room.Details[date] = []
    if (state.room.Details[date].findIndex(d => d.Oid === message.Oid) !== -1) return
    state.room.Details[date].push(message)
  },
  UPDATE_MESSAGE (state, message) {
    const date = message.Date
    state.room.Details[date] = state.room.Details[date].map((chat) => {
      if ((message.Oid && chat.Oid === message.Oid) || (message.Key && message.Key === chat.Key)) {
        return { ...chat, ...message }
      }
      return chat
    })
  },
  INC_UNREAD (state) {
    state.room.Unseen++
  },
  SET_UNREAD (state, payload) {
    state.room.Unseen = payload
  },
  READ (state) {
    state.room.Unseen = 0
  },
  LOGOUT (state) {
    state.room = { Details: [], Unseen: 0 }
  }
}
