const state = {
    conditionNotif: false,
    company: {},
    bottomHeight: 0,
    windowWidth: window.innerWidth,
    loginPopup: false
}

const mutations = {
    SET_CONDITION_NOTIF (state, value) {
        state.conditionNotif = value
    },
    SET_COMPANY (state, value) {
      if (value.User && !value.User.CurrencyObj) {
        value.User.CurrencyObj = { Code: 'IDR', Symbol: 'Rp', decimal: 0 }
      }
        state.company = value
    },
    SET_BOTTOM_HEIGHT (state, value) {
        state.bottomHeight = value
    },
    SET_WINDOW_WIDTH (state, value) {
        state.windowWidth = value
    },
    SET_LOGIN_POPUP (state, value) {
        state.loginPopup = value
    }
}

const actions = {
}

const getters = {
  user (state) {
    return state.company.User
  }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
