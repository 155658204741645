<template>
  <div id="app">
      <router-view></router-view>
      <login-popup />
  </div>
</template>
<script>
export default {
    components: {
        LoginPopup: () => import('@/components/popup/login')
    },
    methods: {
        windowResize () {
            this.$store.commit('service/SET_WINDOW_WIDTH', window.innerWidth)
        }
    },
    mounted () {
        console.log('navigator in app.vue', navigator.appInfo)
    },
    created () {
        window.addEventListener('resize', this.windowResize, false)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.windowResize, false)
    }
}
</script>
<style>
    @import url('https://fonts.googleapis.com/css?family=Roboto');
    @import url('https://fonts.googleapis.com/css?family=Nunito');
    .map {
        z-index: 0;
    }
    .carousel-slide {
        border: none !important;
    }
</style>
