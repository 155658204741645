export default {
  sockets: {
    connect () {
      this.$store.dispatch('socket/connectServer', this.$socket)
    },
    // customEmit: function (data) {
    //     console.log('this method was fired by the socket server. eg: io.emit('customEmit", data)')
    // },
    'new message': function (data) {
      console.log(data)
      const { Detail } = data
        if (Detail.User !== this.user.Oid) {
            // eslint-disable-next-line no-undef
            if (typeof cordova !== 'undefined' && cordova && cordova.plugins && cordova.plugins.notification) {
                // var status = 0
                // eslint-disable-next-line no-undef
                if (cordova.plugins.backgroundMode.isActive()) {
                    // eslint-disable-next-line no-undef
                }
                // // if (status === 0) {

                // }

                // eslint-disable-next-line no-undef
                // cordova.plugins.notification.local.schedule({
                //     title: 'Receive Message Support',
                //     text: data.message,
                //     sound: 'file://sounds/reminder.mp3',
                //     trigger: { every: 'minute', count: 1 },
                //     vibrate: true
                // })
            } else {
              if (this.$route.path.indexOf('chat') === -1) {
                showNotification(this, data)
              }
            }
            // this.$store.dispatch('socket/signalReciveMessage', { server: this.$socket })
        }
        this.$store.dispatch('socket/receiveMessage', data)
        // this.$store.commit('socket/addMessageGuest', data)
    }
    // notification: function (data) {
    //   showNotification(this, data)
    // },
    // 'send signalReciveMessage': function () {
    //     this.$store.commit('socket/set_seenMessage')
    // },
    // 'get roomGuest response': function (data) {
    //     this.$store.commit('socket/set_listRoomGuest', data)
    // },
    // 'get message history by room': function () {
    //     // this.$store.commit('socket/set_messageGuest', data)
    // },
    // 'get roomGuest Config': function (data) {
    //     this.$store.commit('socket/set_roomGuestConfig', data)
    // }
},
  computed: {
    socket () {
      return this.$store.state.socket
    }
  }
}

function showNotification (vm, d) {
  const { Detail } = d
  const { Message, UserObj } = Detail
  const message = `<strong>${UserObj.Name}</strong><br/><p>${Message}</p>`
  vm.$buefy.notification.open({
    duration: 10000,
    message,
    position: 'is-top-right',
    type: 'is-info',
    hasIcon: true
  })
}
