import Vue from 'vue'

export const formatCurrency = (value, currency) => {
  if (!currency) currency = { Code: 'IDR', Symbol: 'Rp', Decimal: 0 }
  if (!value) return ''
  return (
    currency.Symbol +
    ' ' +
    new Intl.NumberFormat('id-ID', {
      maximumFractionDigits: currency.Decimal
    }).format(Number(value))
  )
}

Vue.filter('currency', formatCurrency)
