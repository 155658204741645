import Vue from 'vue'

Vue.mixin({
    methods: {
        getClass (data, container) {
            if (this.windowWidth < 600 && (data.MobileClass || data.MobileContainerClass)) {
                if (container) return data.MobileContainerClass
                else return data.MobileClass
            } else {
                if (container) return data.ContainerClass
                else return data.Class
            }
        },
        getStyle (data, container) {
            if (this.windowWidth < 600 && (data.MobileStyle || data.MobileContainerStyle)) {
                if (container) return data.MobileContainerStyle
                else return data.MobileStyle
            } else {
                if (container) return data.ContainerStyle
                else return data.Style
            }
        }
    }
})
