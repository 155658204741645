<template>
    <div id="app" :class="{ mobile: isMobile }">
      <transition enter-class="opacity-0" enter-active-class="ease-in" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="ease-out duration-300" leave-to-class="opacity-0">
          <div class="fixed inset-0 min-w-full min-h-screen z-50 bg-white transition-opacity" v-if="showSlide && started">
            <b-carousel :autoplay="false">
              <b-carousel-item v-for="(slide, i) in slides" :key="i">
                <div class="absolute w-full px-20" style="z-index: 1; top: 54%;">
                    <div class="w-full justify-center flex">
                        <b-button v-if="slide.Button" :type="slide.Button.Type" class="w-full" @click="closeSlide">{{slide.Button.Text}}</b-button>
                    </div>
                    <div class="text-center text-xs" style="padding-top: 32vh">
                      <div v-if="i == 0" @click="isModalVisible = true">
                        Untuk pelajari lebih lanjut, <span class="has-text-danger font-bold">klik sini</span>
                      </div>
                      <Modal :visible="isModalVisible" @close="isModalVisible = false">
                        <template v-slot:body>
                          <ul>
                            <li v-for="(item, index) in policyList" :key="index" @click="handleItemClick(item)">
                              <div class="flex justify-between w-full mt-4" style="color: #555">
                                <div class="text-lg font-bold">
                                  {{ item.Title }}
                                </div>
                                <div>
                                  <b-icon icon="chevron-right"></b-icon>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </template>
                      </Modal>
                      <div v-if="i == 1" @click="isModalVisible = true" class="w-full justify-center flex">
                        <div class="bg-red-400 w-36 rounded-xl text-xl text-white font-bold shadow-md">Learn More</div>
                      </div>
                    </div>
                </div>
                <b-image :src="slide.ImageIntro"></b-image>
              </b-carousel-item>
            </b-carousel>
          </div>
        </transition>
      <router-view v-if="started"></router-view>
    </div>
</template>
<script>
import socket from './mixin/socket'
import BaseApp from './BaseApp'
export default {
  extends: BaseApp,
  mixins: [socket],
  components: {
      Modal: () => import('@/components/modal/index')
  },
  data () {
    return {
      started: false,
      showSlide: false,
      slides: [],
      config: JSON.parse(localStorage.getItem('config')),
      refToken: localStorage.getItem('refresh_token'),
      companyToken: JSON.parse(localStorage.getItem('company')),
      policyList: [],
      isModalVisible: false
    }
  },
  methods: {
    handleItemClick (item) {
      if (this.isMobile && typeof cordova !== 'undefined') {
        window.cordova.InAppBrowser.open(item.URL, '_blank', 'location=no')
      } else {
        window.open(item.URL, '_blank')
      }
      this.isModalVisible = false
    },
    async checkToken () {
        var token = { company: this.companyToken && this.companyToken.Company, refresh_token: this.refToken }
        const res = await this.$adminApi.post('/checktokenexpiry', token)
        if (res === 1) console.log('')
        else if (res === 0) this.logout()
        else {
            this.config.Token = res.access_token
            localStorage.setItem('config', JSON.stringify(this.config))
            localStorage.setItem('refresh_token', JSON.stringify(res.refresh_token))
        }
    },
    async init () {
      const loading = this.$buefy.loading.open()
      try {
        this.$store.dispatch('webcontent/loadFromCache')
        this.policyList = await this.$baseApi.get('/help')
        await Promise.all([
          this.getCompany(),
          // preload content
          // this.$store.dispatch('webcontent/get', { path: '/home' }),
          this.$store.dispatch('webcontent/get', { path: '/login' }),
          this.$store.dispatch('webcontent/get', { path: '/register' })
        ])
        if (this.user.IsLogin) {
          this.onesignallogin()
          this.onesignal()
          this.$store.dispatch('socket/getNotification')
        }
        this.slides = this.company.Intro
        this.showSlide = !localStorage.getItem('init') && this.isCordova
        this.started = true
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    closeSlide () {
      this.showSlide = false
      localStorage.setItem('init', 1)
    }
  },
  async mounted () {
    // if (this.companyToken && this.refToken) await this.checkToken()
    this.init()
  }
}
</script>
